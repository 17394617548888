import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import DefaultLayout from '@/layouts/default';
import Masthead from '@/components/layout/masthead';
import SEO from '@/components/layout/seo';

const Page = ({ location }) => (
  <DefaultLayout pageInfo={location}>
    <SEO title="Privacy Policy" keywords={[]} />

    <Masthead location={location}>
      <Container className="my-4">
        <Row>
          <Col lg={6}>
            <h2>Privacy Policy</h2>
          </Col>
        </Row>
      </Container>
    </Masthead>

    <Container className="mt-5">
      <Row>
        <Col lg={7}>
          <p>
            Thank you for visiting the Partnership for Pre-K Improvement (PPI)
            Toolkit and reviewing our privacy policy. We respect the privacy of
            all visitors to this website.{' '}
          </p>

          <p>
            <strong>Personal Information.</strong> A valid email address is
            required to access some features of the PPI Toolkit including our
            assessment tool, the Implementation Development Map. We will not
            collect and retain any personally identifiable information about you
            (for example, your name, address, phone number, or e-mail address)
            unless you provide it voluntarily. If you choose to provide this
            information, we will use it to respond to your inquiries or requests
            and for that purpose only. We’ll never sell, rent, lease, or give
            away your information to any third party.{' '}
          </p>

          <p>
            <strong>Analytics.</strong> This website collects standard
            information for the purpose of analyzing website traffic and
            activity, including the IP address of the user, web pages visited,
            and date/time visited. We do not track individual users.
          </p>

          <p>
            <strong>Links to Other Websites.</strong>This website contains links
            to other websites. We are not responsible for the privacy practices
            of these other sites. We encourage our users to read the privacy
            policies of any website to which they connect through the PPI
            Toolkit.{' '}
          </p>

          <p>
            <strong>Policy Changes.</strong> If we change this privacy policy,
            the revised policy will be posted here. If you have questions about
            this policy, <a href="mailto: ppitoolkitinfo@uw.edu">contact us</a>,
            and we will respond to inquiries within 7 working days.
          </p>
        </Col>
      </Row>
    </Container>
  </DefaultLayout>
);

Page.propTypes = {
  location: PropTypes.shape(),
};

Page.defaultProps = {
  location: null,
};

export default Page;
